import { hasPropertyOf, isNullOrUndefined }	from '@cs/core/utils';
import { PropertyAnnotation }            		from './property-annotation.model';
import { SelectionTargetEnum }           		from './selection-target.enum';
import { DataDescribed }                 		from './data-described.model';
import { SelectionTriggerEnum }          		from './selection-trigger.enum';
import { CsDataDescribedClickEventArgs } 		from './actions/cs-data-described-click.event-args';


export enum ApplicationSelectionTargetEnum {
	ExpandDashboard = 'ExpandDashboard',
	PrintPanel      = 'PrintPanel',
}

export interface TargetSelection {
	selectionAction: SelectionTargetEnum;
	selectionValueTargetKey: string;
	selectionValueSourceKey: string;
	selectionValue: string | [] | null;
	selectionRoute: string | null;
}

export interface ApplicationSelectionTargetResult extends SelectionTargetResult {
	selectionMeta: ApplicationSelectionTargetResultMeta;
}

export interface ApplicationSelectionTargetResultMeta {
	applicationAction: ApplicationSelectionTargetEnum;
}

export interface SelectionTargetResult {
	selectionMeta?: { [key: string]: any };
	panelName: string;
	dashboardInstanceId: string;
	selectionAction: SelectionTargetEnum;
	selectionObject: { [key: string]: string | number };
	/**
	 * The page context
	 */
	selectionContext: object;
	selectionRoute: string | null;
	triggerId?: string;
}


export function getSelectionKeys(item: PropertyAnnotation<any>) {
	// check if the value of the property needs to be stored in a different property
	const selectionValueTargetKey = hasPropertyOf(item, 'selectionValueTarget')
																	? item.selectionValueTarget
																	: item.id;
	const selectionValueSourceKey = hasPropertyOf(item, 'selectionValueSource')
																	? item.selectionValueSource
																	: item.id;
	const selectionAction         = hasPropertyOf(item, 'selectionTarget')
																	? item.selectionTarget
																	: null;
	const selectionRoute          = hasPropertyOf(item, 'selectionRoute')
																	? item.selectionRoute
																	: null;
	const selectionValue          = hasPropertyOf(item, 'selectionValue')
																	? item.selectionValue
																	: null;

	return {
		selectionValueTargetKey: selectionValueTargetKey,
		selectionValueSourceKey: selectionValueSourceKey,
		selectionValue:          selectionValue,
		selectionRoute:          selectionRoute,
		selectionAction:         selectionRoute === null
														 ? selectionAction
														 : 'Navigate'
	} as TargetSelection;
}

export function parseSelectionValue(keys: TargetSelection) {

	const result = {};

	const foundKeys = keys.selectionValueTargetKey.split('|');
	const values    = keys.selectionValue as [];
	for (let i = 0; i < foundKeys.length; i++) {
		const key   = foundKeys[i].trim();
		const value = values[i];
		result[key] = value;
	}
	return result;
}

function hasSelectionValueTarget(data: DataDescribed<any, any, any>) {
	return data.dataAnnotation.fields
						 .some(value => hasPropertyOf(value, 'selectionValueTarget'));
}

export const DEFAULT_IPA_ACTION = SelectionTargetEnum.CurrentWindow;

export function getSelectionAction(value: DataDescribed<any, any, any>) {
	let selectionAction = DEFAULT_IPA_ACTION;

	for (const field of value.dataAnnotation.fields) {
		const result = getSelectionKeys(field);
		if (result.selectionAction != null)
			selectionAction = result.selectionAction;
	}
	return selectionAction;
}

/**
 * Helper function for constructing the IPA selection object
 */
export function updateTargetSources(eventArgs: CsDataDescribedClickEventArgs, data: DataDescribed<any, any, any>
	, panelName: string, checkHasSelectionValueTarget = true): SelectionTargetResult {

	// if no selection target we assume that there is no need for emitting an event so return NULL
	if (eventArgs.row == null && (checkHasSelectionValueTarget && !hasSelectionValueTarget(data)))
		return null;

	const result = {};

	const column = eventArgs.column;

	let selectionRoute        = null;
	let selectionAction       = null;
	const selectionMetaEntity = {};

	if (column !== null) {
		if (column.selectionTrigger === SelectionTriggerEnum.Ignore || column.selectionTrigger == null)
			return null;

		if (column.selectionTrigger === SelectionTriggerEnum.Property) {
			const keys = getSelectionKeys(column);

			if (keys.selectionValue == null)
				result[keys.selectionValueTargetKey] = eventArgs.row[keys.selectionValueSourceKey];
			else if (Array.isArray(keys.selectionValue) && keys.selectionValueTargetKey.indexOf('|') > -1)
				Object.assign(result, parseSelectionValue(keys));
			else
				result[keys.selectionValueTargetKey] = keys.selectionValue;

			if (keys.selectionRoute != null)
				selectionRoute = keys.selectionRoute;

			selectionAction = column.selectionTarget;
		}


	}

	// get all entity properties to append the column property
	const filtered = data.dataAnnotation.fields
											 .filter(value => value.selectionTrigger === SelectionTriggerEnum.Entity);

	// Check if there are entity properties present and no column present then there is no selection possible
	if (filtered.length === 0 && column === null)
		return null;

	for (const item of filtered) {
		const keys                           = getSelectionKeys(item);
		result[keys.selectionValueTargetKey] = keys.selectionValue == null
																					 ? eventArgs.row[keys.selectionValueSourceKey]
																					 : keys.selectionValue;

		if (keys.selectionRoute != null)
			selectionRoute = keys.selectionRoute;

		// Added the selection meta for all the entity propertues, WARNING last property value wins when multiple props of the same name.
		if (item.selectionMeta)
			Object.assign(selectionMetaEntity, item.selectionMeta);
	}

	// Check if the action is a panel action then no need this
	if (!isNullOrUndefined(column) && column.selectionTarget === SelectionTargetEnum.PanelAction) {
		selectionAction = SelectionTargetEnum.PanelAction;
	}// Get the last action defined when there is no column
	else if (filtered.length > 0 && selectionAction == null)
		selectionAction = getSelectionAction(data);

	return {
		selectionObject: result,
		selectionAction: selectionAction,
		selectionRoute:  selectionRoute,
		panelName:       panelName,
		// Only provide the entity selection meta when no property IPA item is selected
		selectionMeta: eventArgs.column
									 ? eventArgs.column.selectionMeta
									 : selectionMetaEntity
	} as SelectionTargetResult;
}
