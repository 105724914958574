<div class="cs-data-grid-container">
	<!--	<div class="table-responsive table-editable">-->
	<div class="table-responsive table-editable">
		<!-- Use this template to show the filled data -->

		<ng-container *ngFor="let sheet of sheets">
			<div [class.cs-panel-header]="true"
					 [ngClass]="{'no-data':sheet.settings.hideDataEntry}">

				<div class="table-header"
					 [class.cs-panel-header--header]="true"
					 [ngClass]="{'small-table-header':sheet.settings.alignBtnWithTableHeader}">
					<ng-container *ngIf="hasMergedHeader">
						<ng-content select="[headerFull]"></ng-content>
					</ng-container>
					<ng-container *ngIf="!hasMergedHeader">
						<p *ngIf="sheet.settings.showLabelAction">{{ sheet.properties.label}}</p>

						<div class="btn-group btn-group--sm" *ngIf="options.config.allowDataEntry">
							<!-- dynamic Sheet buttons -->
							<button class="btn {{button.btnClass}}"
									*ngFor="let button of sheet.settings.dynamicSheetActionButtons"
									[disabled]="button.disabled"
									matTooltip="{{button.description}}"
									matTooltipPosition="{{button.tooltipPosition || 'below'}}"
									(click)="sheetActionClicked(3, sheet, {name: button.name})">{{button.label}}
							</button>

							<!-- Default sheet buttons DEPRICATED NEED TO BE REMOVED, Should come from the dynamicSheetActionButtons-->
							<button class="btn btn--add"
									*ngIf="sheet.settings.showAddAction"
									[disabled]="sheet.settings.disableAddAction"
									(click)="sheetActionClicked(2, sheet)"> Add
							</button>
						</div>
						<div *ngIf="!options.config.allowDataEntry">
							<span class="badge badge--warning">Data-entry not allowed</span>
						</div>
					</ng-container>

				</div>
				<div [class.cs-panel-header--content]="true">
					<!-- Used to calculate the cell widths for the table -->
					<div id="width-ruler" class="full-width"></div>
					<table [class.table]="!renderAsForm"
						   [class.table-editable]="!renderAsForm"
						   [class.table-as-form]="renderAsForm"
						   [ngClass]="{'table-nested':options.isNested,
						   'table-fixed':sheet.settings.fixedSize}">

						<colgroup>
							<col *ngFor="let g of sheet.colGroup; trackBy:trackByIdentity"
								 [style.width]="g.width">
						</colgroup>
						<ng-container *ngFor="let group of sheet?.groups">
							<thead class="table-headers"
								   *ngIf="sheet?.settings?.showHeaderAction">
							<tr *ngFor="let thRow of group?.columsRows; let lastrow = last;">
								<th *ngFor="let thCell of thRow.columns"
									class="{{thCell.cssClass}} table-cell table-headers__cell"
									[class.injected]="thCell.cellType === 'Injected'"
									[class.label]="thCell.cellUIState.uiType === 'Label'"
									[class.is-first-headergroup-column]="thCell.isFirstColumnOfHeaderGroup"
									[class.is-last-headergroup-column]="thCell.isLastColumnOfHeaderGroup"
									[class.checkboxlabel]="thCell.cellType === 'Checkbox' && thCell.isLabel"
									[class.cs-pointer]="!options.disableSorting && !thCell.isGroup"
									[attr.colspan]="thCell.colSpan"
									(click)="headerCellClicked($event, thCell, sheet)"
								>
									<div class="text-truncate">
                  <span [matTooltip]="thCell.properties?.description ?
                                      thCell.properties?.description : thCell.value"
						[matTooltipDisabled]="thCell.properties?.description ?
                                              thCell.properties?.disableDescription : !thCell.isTruncated"
						(mouseover)="detectTruncatedField($event, thCell)"
						matTooltipPosition="above">
                    {{thCell.value}}
                  </span>
										<ng-container *ngIf="lastrow && thCell === sortColumn">
                  <span class="sort-direction-icon" *ngIf="sortOrder === -1"><div
						  class="sort-direction-down"></div></span>
											<span class="sort-direction-icon" *ngIf="sortOrder === 1"><div
													class="sort-direction-up"></div></span>
										</ng-container>
									</div>
								</th>
							</tr>
							</thead>
							<tbody class="table-body"
								   [class.has-headergroups]="sheet?.groups[0]?.columsRows?.length > 1 && sheet?.groups[0]?.columsRows[0]?.columns?.length > 2"
								   (click)="genericCellClickHandler($event)">
							<tr class="table-body__row" *ngIf="group?.dataRows?.length === 0">
								<td *ngIf="options.showCheckboxes"></td>
								<td [attr.colspan]="columnSpanLastRow" class="injected">
									{{'NO_DATA_AVAILABLE' | translate}}
								</td>
							</tr>
							<tr #tablerow
								*ngFor="let row of group?.dataRows;trackBy:trackRowsByFn"
								[class.expanded-row-parent]="row.isExpanded"
								[ngClass]="{
               'table-expansion-row':row.rowState === 'Expanded',
               'total-row bold-row':row.rowState === 'Total',
               'spinner-row':row.rowState === 'Spinner'
              }"
								[attr.id]="row.id"
								(click)="rowClicked(row,group,sheet,$event)">
								<td grid-data-td-component
									*ngFor="let cell of row.values;trackBy:trackRowsByFn; let i = index;"
									[cell]="cell"
									[row]="row"
									[group]="group"
									[sheet]="sheet"
									[index]="i"
									[class.is-first-headergroup-column]="cell.isFirstColumnOfHeaderGroup"
									[class.is-middle-headergroup-column]="!(cell.isFirstColumnOfHeaderGroup || cell.isLastColumnOfHeaderGroup)"
									[class.is-last-headergroup-column]="cell.isLastColumnOfHeaderGroup"
								>
								</td>
							</tr>
							</tbody>
						</ng-container>
					</table>

				</div>
			</div>
		</ng-container>

		<div *ngIf="options?.enableContinuesScrolling"
			 [csLoader]="isLoadingNewContent"
			 class="div-loader"
			 [elementClasses]="['mb-4']"
			 [loaderContainerClasses]="['flex','justify-content-center', 'full-width', 'fade']"
			 [loaderAdditionalClasses]="['loader--big']"></div>
	</div>
</div>
